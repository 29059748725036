import React, { Component } from 'react';
import logo from '../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../history';
import { observable } from "mobx";
import { observer } from 'mobx-react';

import {
    Button,
    Row,
    Col,
    Tooltip,
    DatePicker,
    Select,
    Drawer,
    TimePicker,
    Icon,
    Input,
    AutoComplete,
    Card,
    Skeleton,
    Avatar,
    notification,
    badge,
    Rate,
    message
} from 'antd';

import es_ES from 'antd/lib/locale-provider/es_ES';
import authAxios from '../utils/authAxios';

const { Option } = AutoComplete;
const { Meta } = Card;

class Modulos extends Component {

    constructor(props) {
        super(props);
        this.locationWhere = null;

        this.state = {
            loading: false,
            valueSearch: "",
            dataSearch: [],
            icon: null,
            iconSelected: null,
            tiempoEstimado: null,
            numeroAtendiendo: null,
            numeroDisponible: null,
            loadingTomandoNumero: false,

            moduloNombre: null,
            loading: false,
            id_proceso_caja: null,
            numeroActualTomado: null,
            atendiendo: false,
            llamando: false,
            calificar: false,
            raiting: null,
            tryError: 1
        }
    }

    componentDidMount() {
        this.watchCollections();
        this.makeRemoteRequest();
    }

    watchCollections = async () => {
        const mongodb = await this.props.stitchService();
        this.tenants = mongodb.db("production").collection("tenants");
        this.settings = mongodb.db("production").collection("settings");
        this.clientes = mongodb.db("production").collection("clientes");
        this.modulos = mongodb.db("production").collection("modulos");
        this.filas = mongodb.db("production").collection("filas");

        const tenants = await this.tenants.watch();
        const settings = await this.settings.watch();
        const clientes = await this.clientes.watch();
        const modulos = await this.modulos.watch();
        const filas = await this.filas.watch();

        // Set up a change event handler function for the stream
        tenants.onNext((data) => {
            console.log('actualizando tenants')
            this.makeRemoteRequest(true);
        });

        settings.onNext((data) => {
            console.log('actualizando settings')
            this.makeRemoteRequest(true);
        });

        clientes.onNext((data) => {
            console.log('actualizando clientes')
            this.makeRemoteRequest(true);
        });

        modulos.onNext((data) => {
            console.log('actualizando modulos')
            this.makeRemoteRequest(true);
        });

        filas.onNext(async (data) => {
            if (data.operationType === 'delete') {
                if (data.documentKey._id.toString() !== this.state.idNumeroFila) {
                    if (this.state.numeroActualTomado === null) {
                        this.setState({ loadingTomandoNumero: true });
                        await this.updateScreen();
                        this.setState({ loadingTomandoNumero: false });
                    } else {
                        await this.updateScreen();
                    }
                }

                if (this.state.numeroEliminado !== data.documentKey._id.toString()) {
                    if (data.documentKey._id.toString() === this.state.idNumeroFila) {

                        this.setState({
                            numeroEliminado: data.documentKey._id.toString()
                        });

                        await this.cancelarNumero();
                        await this.makeRemoteRequest();
                    }
                }
            }

            if (data.operationType === 'update' && (data.fullDocument.tipoAtencion.toString() === this.props.tipoAtencion)) {
                if (data.fullDocument) {
                    if (data.fullDocument._id.toString() !== this.state.idNumeroFila) {
                        if (this.state.numeroActualTomado === null) {
                            this.setState({ loadingTomandoNumero: true });
                            await this.updateScreen();
                            this.setState({ loadingTomandoNumero: false });
                        } else {
                            await this.updateScreen();
                        }
                    }

                    if (data.fullDocument._id.toString() === this.state.idNumeroFila) {

                        if (data.fullDocument.estado === 'Atendiendo') {
                            var idSucursal = this.props.idSucursal;

                            const fila = await authAxios.get('/tenant/' + idSucursal + '/fila/' + this.state.idNumeroFila, {
                                params: {
                                    id: this.state.idNumeroFila,
                                }
                            });

                            this.setState({
                                llamando: true,
                                calificar: false,
                                numeroAtendiendo: this.state.numeroDisponible,
                                moduloNombre: fila.data.modulo ? fila.data.modulo.nombre : ''
                            });
                        }

                        if (data.fullDocument.estado === 'Atendido') {
                            this.setState({ calificar: true, llamando: false });
                        }
                    }
                }
            }
        });
    }

    updateScreen = async () => {
        var idSucursal = this.props.idSucursal;
        var tipoAtencion = this.props.tipoAtencion;

        return new Promise(async (resolve, reject) => {
            try {
                const screen = await authAxios.get('/tenant/' + idSucursal + '/fila', {
                    params: {
                        filter: {
                            tipoAtencion
                        },
                    }
                });

                this.setState({
                    numeroAtendiendo: screen.data.numeroAtendiendo ? screen.data.numeroAtendiendo.numero : 1,
                    tiempoEstimado: screen.data.tiempoEstimado,
                    numeroDisponible: this.state.numeroActualTomado === null ? screen.data.numero : this.state.numeroActualTomado,
                });

                resolve(true)
            } catch (e) {
                reject(e);
            }
        })

    }

    async tomarNumero() {
        var idSucursal = this.props.idSucursal;

        try {
            this.setState({ loadingTomandoNumero: true });

            await this.updateScreen();

            const numeroTomado = await authAxios.put('/tenant/' + idSucursal + '/fila/' + this.state.idNumeroFila, {
                id: this.state.idNumeroFila,
                data: {
                    estado: 'Pendiente',
                    numero: this.state.numeroDisponible,
                    tiempoEstimado: this.state.tiempoEstimado,
                    numeroAtendiendo: this.state.numeroAtendiendo.id
                },
            });

            await this.makeRemoteRequest();
            this.props.print(numeroTomado.data);

            /*
            this.setState({
                loadingTomandoNumero: false,
                atendiendo: true,
                numeroActualTomado: numeroTomado.data.numero,
            });
            */

        } catch (e) {
            console.log(e);

            this.setState({
                loadingTomandoNumero: false,
            });
        }

    }

    async cancelarNumero() {

        var idSucursal = this.props.idSucursal;
        this.setState({ loadingTomandoNumero: true });

        try {
            await authAxios.delete('/tenant/' + idSucursal + '/fila/', {
                params: {
                    ids: [this.state.idNumeroFila]
                }
            });

            this.setState({
                atendiendo: false,
                llamando: false,
                numeroActualTomado: null
            });

            await this.makeRemoteRequest();

        } catch (e) {
            console.log(e);
        }
    }

    makeRemoteRequest = async (isRealTime = false) => {

        var idSucursal = this.props.idSucursal;
        var tipoAtencion = this.props.tipoAtencion;

        this.setState({
            loadingTomandoNumero: true,
        });

        if (isRealTime === true) {
            return;
        }

        const tipoAtencion = await authAxios.get('/tenant/' + idSucursal + '/tipo-atencion/' + tipoAtencion, {
            params: {
                filter: {
                },
            }
        });

        this.setState({ tipoAtencion: tipoAtencion.data });

        const screen = await authAxios.get('/tenant/' + idSucursal + '/fila', {
            params: {
                filter: {
                    tipoAtencion: this.props.tipoAtencion
                },
            }
        });

        this.setState({
            numeroAtendiendo: screen.data.numeroAtendiendo ? screen.data.numeroAtendiendo.numero : 1,
            tiempoEstimado: screen.data.tiempoEstimado,
            numeroDisponible: screen.data.numero,
            loadingTomandoNumero: false
        });

        const fila = await authAxios.post('/tenant/' + idSucursal + '/fila', {
            data: {
                tipoAtencion: tipoAtencion.data.id,
                estado: 'Visualizando'
            },
        });

        this.setState({
            idNumeroFila: fila.data.id,
        });
    };

    home = () => {
        history.goBack();
    }

    async calificar(val) {
        var idSucursal = this.props.idSucursal;
        this.setState({ raiting: val });

        try {
            await authAxios.put('/tenant/' + idSucursal + '/fila/' + this.state.idNumeroFila, {
                id: this.state.idNumeroFila,
                data: {
                    calificacion: val
                }
            });

            const location = {
                pathname: '/',
            }

            history.push(location);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const Option = Select.Option;
        const { loading, data, dataSearch, infoSucursal } = this.state;

        return (
            <div className="App">
                <div>
                    <Col className="modulo-view mod-view" lg={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 10, height: 300 }} >
                        {this.state.calificar == false ?
                            this.state.llamando == false ?
                                <Row type="flex" justify="start" align="middle">
                                    {this.state.loadingTomandoNumero == false ?
                                        !this.state.atendiendo ?
                                            <a style={{ margin: 'auto', marginTop: 0 }} onClick={() => this.tomarNumero()}>
                                                <Row className="ButtonLlamar" type="flex" justify="center" align="middle">
                                                    <Row>
                                                        {this.state.numeroDisponible != null ?
                                                            <h5 style={{ marginTop: 120, color: 'white', fontSize: 52, marginLeft: '8%' }}>
                                                                {this.state.numeroDisponible}
                                                            </h5>
                                                            :
                                                            <Icon style={{ color: 'white', marginTop: 150, fontSize: 30 }} type="loading" className="loadingNumero"></Icon>
                                                        }
                                                    </Row>
                                                </Row>
                                            </a>
                                            :
                                            <a style={{ margin: 'auto', marginTop: 0 }}>
                                                <Row className="ButtonAtendiendo" type="flex" justify="center" align="middle">
                                                    <h5 style={{ marginTop: 120, color: 'white', fontSize: 52 }}>
                                                        {this.state.numeroDisponible}
                                                    </h5>
                                                </Row>

                                                <Row type="flex" justify="center" align="middle" className="dejarNumero">
                                                    <h5 style={{ color: 'white', marginTop: -50, fontWeight: 'bold', fontSize: 16 }}>
                                                        <label style={{ color: 'white' }}>Número Tomado</label>
                                                    </h5>
                                                </Row>
                                            </a>
                                        :
                                        <a style={{ margin: 'auto', marginTop: 0 }}>
                                            <Row type="flex" justify="start" align="middle">

                                                <h5 style={{ fontSize: 18, color: 'black', marginTop: 100 }}>
                                                    <Icon style={{ marginRight: 10 }} type="loading"></Icon> Cargando...
                                  </h5>
                                            </Row>
                                        </a>
                                    }
                                </Row>
                                :
                                null
                            :
                            null
                        }
                    </Col>
                </div>
            </div>
        );
    }
}

export default observer(Modulos);
