import React, { Component } from 'react';
import logo from '../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../history';
import { observer } from 'mobx-react';
import authAxios from '../utils/authAxios';
import { getDistance } from 'geolib';
import _ from 'lodash';
import { getLinkString } from '../utils/hooks';

import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar
} from 'antd';

const { Option } = AutoComplete;
const { Meta } = Card;

class Home extends Component {

  constructor(props) {
    super(props);
    this.locationWhere = null;

    this.state = {
      loading: false,
      valueSearch: "",
      dataSearch: [],
    }
  }

  async componentDidMount() {

    this.watchCollections();

    this.setState({ loading: true });
    this.makeRemoteRequest();
  }

  watchCollections = async () => {
    const { dispatch, currentUser } = this.props;

    const mongodb = await this.props.stitchService();
    this.tenants = mongodb.db("production").collection("tenants");
    this.settings = mongodb.db("production").collection("settings");
    this.clientes = mongodb.db("production").collection("clientes");

    const tenants = await this.tenants.watch();
    const settings = await this.settings.watch();
    const clientes = await this.clientes.watch();

    // Set up a change event handler function for the stream
    tenants.onNext((data) => {
      this.makeRemoteRequest();
    });

    settings.onNext((data) => {
      this.makeRemoteRequest();
    });

    clientes.onNext((data) => {
      this.makeRemoteRequest();
    });
  }

  makeRemoteRequest = () => {

    var that = this;

    navigator.geolocation.getCurrentPosition(
      async function (position) {

        let longitudActual = position.coords.longitude;
        let latitudActual = position.coords.latitude;

        const sucursales = await authAxios.get(`/tenant/`, {
          params: {
            filter: {
              public: true,
            },
            orderBy: 'createdAt_DESC',
          }
        });
        let clientes = [];

        await Promise.all(sucursales.data.rows.filter((sucursal) => {
          let distance = 0;

          if (sucursal.settings.latitud && sucursal.settings.longitud) {
            distance = getDistance(
              { latitude: latitudActual, longitude: longitudActual },
              { latitude: sucursal.settings.latitud, longitude: sucursal.settings.longitud }
            );
          }

          return distance < 50000 && sucursal.settings.activacion === true && sucursal.cliente;
        }).map((sucursal) => {
          clientes[sucursal.cliente.id] = sucursal.cliente;
        }));

        let dataClientes = [];
        dataClientes = Object.keys(clientes).map((id) => {
          return clientes[id];
        });

        that.setState({
          data: dataClientes,
          loading: false
        })
      },
      async function (e) {
        const sucursales = await authAxios.get(`/tenant/`, {
          params: {
            filter: {
              public: true,
            },
            orderBy: 'createdAt_DESC',
          }
        });
        let clientes = [];

        await Promise.all(sucursales.data.rows.filter((sucursal) =>
          sucursal.settings.activacion === true && sucursal.cliente
        ).map((sucursal) => {
          clientes[sucursal.cliente.id] = sucursal.cliente;
        }));

        let dataClientes = [];

        dataClientes = Object.keys(clientes).map((id) => {
          return clientes[id];
        });

        that.setState({
          data: dataClientes,
          loading: false
        })
      },
      {
        enableHighAccuracy: true, timeout: 20000
      }
    );
  };

  goSucursales = (item) => {
    const location = {
      pathname: '/sucursales/' + getLinkString(item.nombre) + '/' + item.id,
    }

    history.push(location);
  }

  _searching = (text) => {

    var text = text.target.value;
    var { data } = this.state;

    const newData = data.filter(function (item) {
      const itemData = item.nombre.toUpperCase();
      const textData = text.toUpperCase();

      return itemData.indexOf(textData) > - 1;
    })

    this.setState({
      dataSearch: newData,
      valueSearch: text
    })

  }

  home = () => {
    const location = {
      pathname: '/',
    }

    history.push(location);
  }

  render() {
    const Option = Select.Option;
    const { loading, data, dataSearch } = this.state;

    if (data && data.length === 0) {
      return (
        <div className="App">
          <div className="Rooms-Home">
            <Row className="App-Header-Rooms">
              <Col lg={{ offset: 10, span: 2 }} xs={{ span: 3 }} >
                <Row className="logoHeader" type="flex" justify="start" align="middle">
                  <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                </Row>
              </Col>
            </Row>
            <Row style={{ padding: 20 }}>
              <Tooltip
                trigger={['focus']}
                title={'¿Te ayudo?'}
                placement="bottomLeft"
              >
                <Input
                  value={this.state.valueSearch}
                  onChange={this._searching.bind(this)}
                  style={{ height: '5vh', borderRadius: 5 }} prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" placeholder="Buscar..." />
              </Tooltip>
            </Row>

            <Col className="sucursal-view" lg={{ span: 12 }} xs={{ span: 24 }} style={{ padding: 20 }} >
              <label onClick={this.home} style={{ color: 'black' }}><Icon style={{ color: 'black' }} type="exclamation-circle" /> ¡Lo sentimos, no hemos encontrado resultados!</label>
            </Col>
          </div>
        </div>
      )
    }

    if (dataSearch == '') {
      return (
        <div className="App">
          <div className="Rooms-Home">
            <Row className="App-Header-Rooms">
              <Col lg={{ offset: 10, span: 2 }} xs={{ span: 3 }} >
                <Row className="logoHeader" type="flex" justify="start" align="middle">
                  <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                </Row>
              </Col>
            </Row>
            <Row style={{ padding: 20 }}>
              <Tooltip
                trigger={['focus']}
                title={'¿Que Buscas?'}
                placement="bottomLeft"
              >
                <Input
                  value={this.state.valueSearch}
                  onChange={this._searching.bind(this)}
                  style={{ height: '60px', borderRadius: 5 }} prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" placeholder="Buscar..." />
              </Tooltip>
            </Row>

            <Row style={{ padding: 20, paddingTop: 0 }}>
              <h1 className="title-seccion" style={{ borderBottom: '1px solid rgb(228, 228, 228)', marginBottom: 20, height: 60 }}>Comercios Encontrados</h1>

              {data ?
                data.map((d, index) => {
                  return (
                    <Col onClick={() => this.goSucursales(d)} key={d.id} className="home-comercios" lg={{ span: 8 }} xs={{ span: 24 }} style={{ padding: 10 }} >
                      <Card hoverable={true} style={{ width: '100%' }}
                        actions={[<Row onClick={() => this.goSucursales(d)}><label>Seleccionar <Icon type="arrow-right" /></label></Row>]}
                      >
                        <Skeleton loading={loading} avatar active>
                          <Meta
                            avatar={
                              <Avatar shape="square" size="large" src={d.foto.map((item) => {
                                return item.downloadUrl;
                              })} />
                            }
                            title={d.nombre}
                          />
                        </Skeleton>
                      </Card>
                    </Col>
                  );
                })
                :
                <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                  <Meta
                    avatar={
                      <Avatar shape="square" size="large" src='' />
                    }
                  />
                </Skeleton>
              }
            </Row>
          </div>
        </div>
      );
    } else {

      return (
        <div className="App">
          <div className="Rooms-Home">
            <Row className="App-Header-Rooms">
              <Col lg={{ offset: 10, span: 2 }} xs={{ span: 3 }} >
                <Row className="logoHeader" type="flex" justify="start" align="middle">
                  <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                </Row>
              </Col>
            </Row>
            <Row style={{ padding: 20 }}>
              <Tooltip
                trigger={['focus']}
                title={'¿Que Buscas?'}
                placement="bottomLeft"
              >
                <Input
                  value={this.state.valueSearch}
                  onChange={this._searching.bind(this)}
                  style={{ height: '60px', borderRadius: 5 }} prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" placeholder="Buscar..." />
              </Tooltip>
            </Row>

            <Row style={{ padding: 20, paddingTop: 0 }}>
              <h1 className="title-seccion" style={{ borderBottom: '1px solid rgb(228, 228, 228)', marginBottom: 20, height: 60 }}>Comercios Encontrados</h1>

              {dataSearch ?
                dataSearch.map((d, index) => {
                  return (
                    <Col onClick={() => this.goSucursales(d)} key={d.id} className="home-comercios" lg={{ span: 8 }} xs={{ span: 24 }} style={{ padding: 10 }} >
                      <Card hoverable={true} style={{ width: '100%' }}
                        actions={[<Row onClick={() => this.goSucursales(d)}><label>Seleccionar <Icon type="arrow-right" /></label></Row>]}
                      >
                        <Skeleton loading={loading} avatar active>
                          <Meta
                            avatar={
                              <Avatar shape="square" size="large" src={d.foto.map((item) => {
                                return item.downloadUrl;
                              })} />
                            }
                            title={d.nombre}
                          />
                        </Skeleton>
                      </Card>
                    </Col>
                  );
                })
                :
                <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                  <Meta
                    avatar={
                      <Avatar shape="square" size="large" src='' />
                    }
                  />
                </Skeleton>
              }
            </Row>
          </div>
        </div>
      );
    }

  }
}


export default observer(Home);
