import React, { Component } from 'react';
import { Router, Route } from "react-router-dom";
import Home from './pages/Home';
import Sucursales from './pages/Sucursales';
import Modulos from './pages/TiposAtencion';
import Modulo from './pages/Modulo';
import Pantalla from './pages/Pantalla';
import Totem from './pages/Totem';
import history from './history';
import { message } from 'antd';
import { getServiceStitch } from './utils/hooks';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <Route exact path="/"
            render={(props) => <Home stitchService={getServiceStitch} {...props} />} />
          <Route exact path="/sucursales/:nombreCliente/:idCliente"
            render={(props) => <Sucursales stitchService={getServiceStitch} {...props} />} />
          <Route exact path="/tipos-atencion/:nombreSucursal/:idSucursal"
            render={(props) => <Modulos stitchService={getServiceStitch} {...props} />} />
          <Route exact path="/interfaz-pantalla/:nombrePantalla/:idSucursal/:idPantalla"
            render={(props) => <Pantalla stitchService={getServiceStitch} {...props} />}
          />
          <Route exact path="/interfaz-totem/:nombrePantalla/:idSucursal/:idPantalla"
            render={(props) => <Totem stitchService={getServiceStitch} {...props} />}
          />
          <Route exact path="/atencion/:nombreTipoAtencion/:tipoAtencion/:idSucursal"
            render={(props) => <Modulo stitchService={getServiceStitch} {...props} />}
          />
        </div>
      </Router>
    );
  }
}

export default App;
