import { Avatar, Col, message, Row } from 'antd';
import React, { Component } from 'react';
import authAxios from '../utils/authAxios';

class Pantalla extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modulos: [],
            tryError: 1
        }
    }

    componentDidMount = async () => {

        this.watchCollections();
        this.init();
    }

    init = async () => {
        const modulos = await authAxios.get('tenant/' + this.props.idSucursal + '/modulo', {
            params: {
                filter: {
                    populate: true,
                    todosTipoAtencion: this.props.tipoAtencion,
                    estado: 'Activo'
                },
                orderBy: 'createdAt_DESC',
            }
        });

        if (modulos.data.rows.length === 0) {
            document.getElementById('tipo-atencion-' + this.props.tipoAtencion).style.display = 'none';
        } else {
            document.getElementById('tipo-atencion-' + this.props.tipoAtencion).style.display = 'block';
        }

        this.setState({
            modulos: modulos.data.rows
        });
    }

    watchCollections = async () => {
        const { dispatch, currentUser } = this.props;

        const mongodb = await this.props.stitchService();
        this.filas = mongodb.db("production").collection("filas");

        const filas = await this.filas.watch();

        filas.onNext((data) => {
            this.init();

            if (data.fullDocument.estado === 'Atendiendo') {
                document.getElementById(data.fullDocument.tipoAtencion.toString() + '-' + data.fullDocument.modulo.toString()).style.backgroundColor = '#FC450A';
                document.getElementById(data.fullDocument.tipoAtencion.toString() + '-' + data.fullDocument.modulo.toString()).className = 'animacion';

                setTimeout(() => {
                    document.getElementById(data.fullDocument.tipoAtencion.toString() + '-' + data.fullDocument.modulo.toString()).style.backgroundColor = 'black';
                    document.getElementById(data.fullDocument.tipoAtencion.toString() + '-' + data.fullDocument.modulo.toString()).className = 'no-animacion';
                }, 8000);
            }
        });
    }

    render = () => {
        return (
            <React.Fragment>
                {this.state.modulos.length > 0 ?
                    this.state.modulos.map((modulo) => {
                        return (
                            <React.Fragment key={modulo.id}>
                                <Row id={this.props.tipoAtencion + '-' + modulo.id} style={{ borderRadius: 10, backgroundColor: 'black', width: '100%', height: 100, marginBottom: 20 }}>
                                    <Col span={12} >
                                        <Row style={{ height: 100 }} type={'flex'} justify={'center'} align={'middle'}>
                                            <h1 style={{ color: 'white' }}>{modulo.nombre}:</h1>
                                        </Row>
                                    </Col>
                                    <Col span={12} justify={'center'} align={'middle'}>
                                        <Row style={{ height: 100 }} type={'flex'} justify={'center'} align={'middle'}>
                                            <Avatar size="large" style={{ color: 'black', backgroundColor: 'white', fontSize: 32 }}>{modulo.numeroActual}</Avatar>
                                        </Row>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )
                    })
                    : null}
            </React.Fragment>
        );
    }
}

export default Pantalla;