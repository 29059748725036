import React, { Component } from 'react';
import logo from '../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../history';
import { observable } from "mobx";
import { observer } from 'mobx-react';
import { getLinkString } from '../utils/hooks';

import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar
} from 'antd';

import es_ES from 'antd/lib/locale-provider/es_ES';
import authAxios from '../utils/authAxios';

const { Option } = AutoComplete;
const { Meta } = Card;

class Modulos extends Component {

  constructor(props) {
    super(props);
    this.locationWhere = null;

    this.state = {
      loading: false,
      valueSearch: "",
      dataSearch: [],
    }
  }

  async componentDidMount() {
    this.watchCollections();

    this.setState({ loading: true });
    this.makeRemoteRequest();
  }

  watchCollections = async () => {
    const { dispatch, currentUser } = this.props;

    const mongodb = await this.props.stitchService();
    this.tenants = mongodb.db("production").collection("tenants");
    this.settings = mongodb.db("production").collection("settings");
    this.clientes = mongodb.db("production").collection("clientes");
    this.modulos = mongodb.db("production").collection("modulos");
    this.tiposAtenciones = mongodb.db("production").collection("tipoatencions");

    const tenants = await this.tenants.watch();
    const settings = await this.settings.watch();
    const clientes = await this.clientes.watch();
    const modulos = await this.modulos.watch();
    const tiposAtenciones = await this.tiposAtenciones.watch();

    // Set up a change event handler function for the stream
    tenants.onNext((data) => {
      this.makeRemoteRequest();
    });

    settings.onNext((data) => {
      this.makeRemoteRequest();
    });

    clientes.onNext((data) => {
      this.makeRemoteRequest();
    });

    modulos.onNext((data) => {
      this.makeRemoteRequest();
    });

    tiposAtenciones.onNext((data) => {
      this.makeRemoteRequest();
    });
  }

  makeRemoteRequest = async () => {

    var idSucursal = this.props.match.params.idSucursal;

    const sucursal = await authAxios.get('tenant/' + idSucursal, {
      params: {
        filter: {
        },
      }
    });

    if (sucursal.data.settings.activacion) {
      this.setState({ infoSucursal: sucursal.data });
    } else {
      this.setState({ infoSucursal: false });
    }

    const modulos = await authAxios.get('tenant/' + idSucursal + '/modulo', {
      params: {
        filter: {
          populate: true,
          estado: 'Activo'
        },
        orderBy: 'createdAt_DESC',
      }
    });

    let tiposAtencion = [];

    await Promise.all(modulos.data.rows.map((item) => {
      return item.tiposAtencionActivados.map((row) => {
        tiposAtencion[row.id] = row;
      })
    }));

    let dataTiposAtencion = [];
    dataTiposAtencion = Object.keys(tiposAtencion).map((id) => {
      return tiposAtencion[id];
    });

    this.setState({
      data: dataTiposAtencion,
      loading: false,
      refreshing: false
    });
  };

  goModulo = (item) => {

    const location = {
      pathname: '/atencion/' + getLinkString(item.nombre) + '/' + item.id + '/' + this.props.match.params.idSucursal,
    }

    history.push(location);
  }

  _searching = (text) => {

    var text = text.target.value;
    var { data } = this.state;

    const newData = data.filter(function (item) {
      const itemData = item.nombre.toUpperCase();
      const textData = text.toUpperCase();

      return itemData.indexOf(textData) > - 1;
    })

    this.setState({
      dataSearch: newData,
      valueSearch: text
    })

  }

  home = () => {
    const { infoSucursal } = this.state;

    if (infoSucursal) {
      history.goBack();
    } else {
      history.push('/');
    }

  }

  render() {
    const Option = Select.Option;
    const { loading, data, dataSearch, infoSucursal } = this.state;

    if ((data && data.length === 0) || (infoSucursal === false)) {
      return (
        <div className="App">
          <div className="Rooms-Home">
            <Row className="App-Header-Rooms">
              <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
                <Row className="logoHeader" type="flex" justify="start" align="middle">
                  <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
                </Row>
              </Col>
              <Col lg={{ span: 11 }} xs={{ span: 11 }} >
                <Row className="logoHeader" type="flex" justify="end" align="middle">
                  <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                </Row>
              </Col>
            </Row>
            <Row style={{ height: '10vh', padding: 20 }}>
              <Tooltip
                trigger={['focus']}
                title={'¿Te ayudo?'}
                placement="bottomLeft"
              >
                <Input
                  value={this.state.valueSearch}
                  onChange={this._searching.bind(this)}
                  style={{ height: '60px', borderRadius: 5 }} prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" placeholder="Buscar..." />
              </Tooltip>
            </Row>

            <Col className="sucursal-view" lg={{ span: 12 }} xs={{ span: 24 }} style={{ padding: 20 }} >
              <label onClick={this.home} style={{ color: 'black' }}><Icon style={{ color: 'black' }} type="exclamation-circle" /> ¡Lo sentimos, no hemos encontrado resultados!</label>
            </Col>
          </div>
        </div>
      )
    }

    if (dataSearch == '') {
      return (
        <div className="App">
          <div className="Rooms-Home">
            <Row className="App-Header-Rooms">
              <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
                <Row className="logoHeader" type="flex" justify="start" align="middle">
                  <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
                </Row>
              </Col>
              <Col lg={{ span: 11 }} xs={{ span: 11 }} >
                <Row className="logoHeader" type="flex" justify="end" align="middle">
                  <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                </Row>
              </Col>
            </Row>
            <Row style={{ height: '10vh', padding: 20 }}>
              <Tooltip
                trigger={['focus']}
                title={'¿Te ayudo?'}
                placement="bottomLeft"
              >
                <Input
                  value={this.state.valueSearch}
                  onChange={this._searching.bind(this)}
                  style={{ height: '60px', borderRadius: 5 }} prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" placeholder="Buscar..." />
              </Tooltip>
            </Row>

            <Col className="sucursal-view" lg={{ span: 6 }} xs={{ span: 24 }} style={{ padding: 20 }} >

              {infoSucursal ?
                <Card
                  hoverable
                  style={{ width: '100%', marginTop: 0 }}
                  cover={<img alt="img" src={infoSucursal.settings.backgroundImages.map((item) => {
                    return item.publicUrl;
                  })} />}
                >
                  <h4><img style={{ width: 200 }} alt="img" src={infoSucursal.cliente.foto.map((item) => {
                    return item.downloadUrl;
                  })} /> </h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="info-circle" /> {infoSucursal.name}</h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="clock-circle" /> {moment(infoSucursal.settings.horarioInicio).format('HH:mm') + ' - ' + moment(infoSucursal.settings.horarioCierre).format('HH:mm')}</h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="home" /> {infoSucursal.settings.ubicacionGoogle}</h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="phone" /> {infoSucursal.settings.telefono}</h4>
                </Card>
                :
                <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                  <Meta
                    avatar={
                      <Avatar shape="square" size="large" src='' />
                    }
                  />
                </Skeleton>
              }
            </Col>

            <Col className="modulo-view" lg={{ span: 18 }} xs={{ span: 24 }} style={{ padding: 10 }} >
              <Row style={{ height: '82vh', padding: 20, paddingTop: 0 }}>

                {infoSucursal && infoSucursal.settings ?
                  <h1 className="title-seccion" style={{ borderBottom: '1px solid rgb(228, 228, 228)', marginBottom: 20, height: 60 }}>
                    Tipos de Atención
                  </h1>
                  : null}

                {data !== undefined ?
                  data.map((d, index) => {
                    return (
                      <Col onClick={() => this.goModulo(d)} key={d.id} className="home-sucursales" lg={{ span: 12 }} xs={{ span: 24 }} style={{ padding: 10 }} >
                        <Card hoverable={true} style={{ width: '100%' }}
                          actions={[<Row onClick={() => this.goModulo(d)}><label>Seleccionar <Icon type="arrow-right" /></label></Row>]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <Avatar shape="square" size="large" src={d.icono.map((item) => {
                                  return item.downloadUrl;
                                })} />
                              }
                              title={d.nombre}
                            />
                          </Skeleton>
                        </Card>
                      </Col>
                    );
                  })
                  :
                  <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                    <Meta
                      avatar={
                        <Avatar shape="square" size="large" src='' />
                      }
                    />
                  </Skeleton>
                }
              </Row>
            </Col>
          </div>
        </div>
      );
    } else {

      return (
        <div className="App">
          <div className="Rooms-Home">
            <Row className="App-Header-Rooms">
              <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
                <Row className="logoHeader" type="flex" justify="start" align="middle">
                  <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
                </Row>
              </Col>
              <Col lg={{ span: 11 }} xs={{ span: 11 }} >
                <Row className="logoHeader" type="flex" justify="end" align="middle">
                  <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                </Row>
              </Col>
            </Row>
            <Row style={{ height: '10vh', padding: 20 }}>
              <Tooltip
                trigger={['focus']}
                title={'¿Te ayudo?'}
                placement="bottomLeft"
              >
                <Input
                  value={this.state.valueSearch}
                  onChange={this._searching.bind(this)}
                  style={{ height: '60px', borderRadius: 5 }} prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" placeholder="Buscar..." />
              </Tooltip>
            </Row>

            <Col className="sucursal-view" lg={{ span: 6 }} xs={{ span: 24 }} style={{ padding: 20 }} >

              {infoSucursal ?
                <Card
                  hoverable
                  style={{ width: '100%', marginTop: 0 }}
                  cover={<img alt="img" src={infoSucursal.settings.backgroundImages.map((item) => {
                    return item.publicUrl;
                  })} />}
                >
                  <h4><img style={{ width: 200 }} alt="img" src={infoSucursal.cliente.foto.map((item) => {
                    return item.downloadUrl;
                  })} /> </h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="info-circle" /> {infoSucursal.name}</h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="clock-circle" /> {moment(infoSucursal.settings.horarioInicio).format('HH:mm') + ' - ' + moment(infoSucursal.settings.horarioCierre).format('HH:mm')}</h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="home" /> {infoSucursal.settings.ubicacionGoogle}</h4>
                  <h4 style={{ fontWeight: '600' }}><Icon type="phone" /> {infoSucursal.settings.telefono}</h4>
                </Card>
                :
                <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                  <Meta
                    avatar={
                      <Avatar shape="square" size="large" src='' />
                    }
                  />
                </Skeleton>
              }
            </Col>

            <Col className="modulo-view" lg={{ span: 18 }} xs={{ span: 24 }} style={{ padding: 10 }} >
              <Row style={{ height: '82vh', padding: 20, paddingTop: 0 }}>

                {infoSucursal && infoSucursal.settings ?
                  <h1 className="title-seccion" style={{ borderBottom: '1px solid rgb(228, 228, 228)', marginBottom: 20, height: 60 }}>
                    Tipos de Atención
                  </h1>
                  : null}

                {dataSearch !== undefined ?
                  dataSearch.map((d, index) => {
                    return (
                      <Col onClick={() => this.goModulo(d)} key={d.id} className="home-sucursales" lg={{ span: 12 }} xs={{ span: 24 }} style={{ padding: 10 }} >
                        <Card hoverable={true} style={{ width: '100%' }}
                          actions={[<Row onClick={() => this.goModulo(d)}><label>Seleccionar <Icon type="arrow-right" /></label></Row>]}
                        >
                          <Skeleton loading={loading} avatar active>
                            <Meta
                              avatar={
                                <Avatar shape="square" size="large" src={d.icono.map((item) => {
                                  return item.downloadUrl;
                                })} />
                              }
                              title={d.nombre}
                            />
                          </Skeleton>
                        </Card>
                      </Col>
                    );
                  })
                  :
                  <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                    <Meta
                      avatar={
                        <Avatar shape="square" size="large" src='' />
                      }
                    />
                  </Skeleton>
                }
              </Row>
            </Col>
          </div>
        </div>
      );
    }

  }
}

export default observer(Modulos);
