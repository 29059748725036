import {
    Stitch,
    AnonymousCredential,
    RemoteMongoClient,
    BSON,
    UserApiKeyCredential
} from "mongodb-stitch-browser-sdk";

import { message } from 'antd';

var accents = require('remove-accents');

const getServiceStitch = async () => {
    const client = Stitch.hasAppClient('stitch-app-xintw')
        ? Stitch.getAppClient('stitch-app-xintw')
        : Stitch.initializeAppClient('stitch-app-xintw');

    const credential = new UserApiKeyCredential("xO5tqb4iDqtDJbFWnE0mYwZ5Wq554b6Qxaheh0I76IUqZqnuA9omGSAIu7USk8vr")

    await client.auth.loginWithCredential(credential);
    return client.getServiceClient(RemoteMongoClient.factory, "mongodb-atlas");
}

const getLinkString = (string) => {

    if (string) {
        string = string.replaceAll("?", "");
        string = string.replaceAll("¿", "");
        string = string.replaceAll("-", "");
        string = string.replaceAll("_", "");
        string = string.replaceAll('"', "");
        string = string.replaceAll('"', "");
        string = string.replaceAll('`', "");
        string = string.replaceAll(':', "");
        string = string.replaceAll('.', "");
        string = string.replaceAll("%", " por ciento ");
        string = string.replaceAll("/", "");
        string = string.replaceAll("#", "");

        return accents.remove(string.replaceAll(/ /g, "-")).toLowerCase();
    } else {
        return null;
    }
}

export {
    getLinkString,
    getServiceStitch
}