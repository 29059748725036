import React, { Component } from 'react';
import logo from '../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../history';
import { observable } from "mobx";
import { observer } from 'mobx-react';
import { getLinkString } from '../utils/hooks';
import ModuloTotem from '../pages/ModuloTotem';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';

import {
    Button,
    Row,
    Col,
    Tooltip,
    DatePicker,
    Select,
    Drawer,
    TimePicker,
    Icon,
    Input,
    AutoComplete,
    Card,
    Skeleton,
    Avatar,
    Modal,
    message,
    notification
} from 'antd';

import es_ES from 'antd/lib/locale-provider/es_ES';
import authAxios from '../utils/authAxios';

const { Option } = AutoComplete;
const { Meta } = Card;

class Totem extends Component {

    constructor(props) {
        super(props);
        this.locationWhere = null;

        this.state = {
            loading: false,
            valueSearch: "",
            dataSearch: [],
            modalVisible: false,
            passwordSuccess: false,
            tryError: 1
        }
    }

    async componentDidMount() {
        /*
        notification.destroy();

        const args = {
            placement: 'topLeft',
            message: 'Importante',
            description: 'Una vez tomes tu número debes acudir a la sucursal, si no estás a tiempo perderas tu puesto y otro tomará tu posición.',
            duration: 0,
        };

        notification.open(args);
        */

        this.watchCollections();

        this.setState({ loading: true });
        this.init();
    }

    watchCollections = async () => {
        const { dispatch, currentUser } = this.props;

        const mongodb = await this.props.stitchService();
        this.tenants = mongodb.db("production").collection("tenants");
        this.settings = mongodb.db("production").collection("settings");
        this.clientes = mongodb.db("production").collection("clientes");
        this.modulos = mongodb.db("production").collection("modulos");
        this.interfaces = mongodb.db("production").collection("interfazs");

        const tenants = await this.tenants.watch();
        const settings = await this.settings.watch();
        const clientes = await this.clientes.watch();
        const modulos = await this.modulos.watch();
        const interfaces = await this.interfaces.watch();

        // Set up a change event handler function for the stream
        tenants.onNext((data) => {
            this.init();
        });

        settings.onNext((data) => {
            this.init();
        });

        clientes.onNext((data) => {
            this.init();
        });

        modulos.onNext((data) => {
            this.init();
        });

        interfaces.onNext((data) => {
            this.init();
        });
    }

    init = async () => {

        var idSucursal = this.props.match.params.idSucursal;
        var idPantalla = this.props.match.params.idPantalla;

        message.destroy();

        this.setState({
            enabledTotem: true,
        });

        const totem = await authAxios.get('/tenant/' + idSucursal + '/interfaz/' + idPantalla, {
            params: {
                filter: {
                    tipo: 'Pantalla Sucursal',
                    estado: 'Encendida'
                },
            }
        });

        if (totem.data.tipo !== 'Totem') {
            this.setState({
                enabledTotem: false,
            });

            message.config({
                top: 400,
                duration: 100,
                maxCount: 3,
            });

            message.error('Totem ' + totem.data.nombre + ' no es un Totem de Sucursal');
            return;
        }

        if (totem.data.estado === 'Apagada') {
            this.setState({
                enabledTotem: false,
            });

            message.config({
                top: 400,
                duration: 100,
                maxCount: 3,
            });

            message.error('Totem ' + totem.data.nombre + ' se encuentra apagado');
            return;
        }

        this.setState({
            totem: totem.data,
        });

        if (!this.state.passwordSuccess) {
            this.showModal();
        }

        const sucursal = await authAxios.get('tenant/' + idSucursal, {
            params: {
                filter: {
                },
            }
        });

        if (sucursal.data.settings.activacion) {
            this.setState({ infoSucursal: sucursal.data });
        } else {
            this.setState({ infoSucursal: false });
        }

        let tiposAtencion = [];

        await Promise.all(totem.data.tiposAtencion.map((item) => {
            tiposAtencion[item.id] = item;
        }));

        let dataTiposAtencion = [];
        dataTiposAtencion = Object.keys(tiposAtencion).map((id) => {
            return tiposAtencion[id];
        });

        this.setState({
            data: dataTiposAtencion,
            loading: false,
            refreshing: false
        });
    };

    _searching = (text) => {

        var text = text.target.value;
        var { data } = this.state;

        const newData = data.filter(function (item) {
            const itemData = item.nombre.toUpperCase();
            const textData = text.toUpperCase();

            return itemData.indexOf(textData) > - 1;
        })

        this.setState({
            dataSearch: newData,
            valueSearch: text
        })

    }

    home = () => {
        const { infoSucursal } = this.state;

        if (infoSucursal) {
            history.goBack();
        } else {
            history.push('/');
        }

    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        });
    };

    confirmPassword = e => {

        if (this.state.totem.token === this.state.entryPassword) {
            this.setState({
                modalVisible: false,
                passwordSuccess: true
            });
        } else {
            message.config({
                top: 100,
                duration: 2,
                maxCount: 3,
            });

            message.error('La contraseña ingresada es incorrecta');
        }
    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
        });
    };

    print = (numeroTomado) => {
        this.setState({
            numeroTomado
        });

        window.print();
    }

    render() {
        const Option = Select.Option;
        const { loading, data, dataSearch, infoSucursal } = this.state;

        if (this.state.enabledTotem === false) {
            return (<React.Fragment>
                <div className="App">
                    <div className="Rooms-Home">
                        <Row className="App-Header-Rooms">
                            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
                            </Col>
                            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
                                <Row className="logoHeader" type="flex" justify="end" align="middle">
                                    <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>)
        }

        if (dataSearch == '') {
            return (
                <PrintProvider>
                    <Print printOnly single>
                        {this.state.numeroTomado ?
                            <div style={{
                                height: 0,
                                maxHeight: 0,
                                marginTop: 50,
                                marginBottom: 0,
                                padding: 0,
                                textAlign: 'center', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <h1>SUCURSAL</h1>
                                <h2>{this.state.infoSucursal.name}</h2>
                                <hr></hr>
                                <h1>NÚMERO</h1>
                                <h2>{this.state.numeroTomado.numero}</h2>
                                <hr></hr>
                                <h1>TIPO DE ATENCIÓN</h1>
                                <h2>{this.state.numeroTomado.tipoAtencion.nombre}</h2>
                                <hr></hr>
                                <h1>FECHA DE ATENCIÓN</h1>
                                <h2>{moment(this.state.numeroTomado.createdAt).format('DD-MM-YYYY')}</h2>
                            </div>
                            : null}
                    </Print>
                    <NoPrint>
                        <div className="App">
                            <div className="Rooms-Home">
                                <Modal
                                    closable={false}
                                    centered
                                    okText={'Confirmar Contraseña'}
                                    title="Ingrese Contraseña de Acceso"
                                    visible={this.state.modalVisible}
                                    okButtonProps={{ style: { borderRadius: 10, fontSize: 24, backgroundColor: '#FC450A', height: 60, borderWidth: 0 } }}
                                    cancelButtonProps={{ style: { display: 'none' } }}
                                    onOk={this.confirmPassword}
                                >
                                    {this.state.totem ?
                                        <Row style={{ padding: 20 }}>
                                            <Input
                                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                type="password"
                                                onChange={e => {
                                                    this.setState({
                                                        entryPassword: e.target.value
                                                    })
                                                }}
                                                style={{ height: 60 }}
                                                size="large" placeholder={"Ingrese Contraseña " + this.state.totem.nombre} />
                                        </Row>
                                        : null}

                                </Modal>

                                <Row className="App-Header-Rooms">
                                    <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
                                    </Col>
                                    <Col lg={{ span: 11 }} xs={{ span: 11 }} >
                                        <Row className="logoHeader" type="flex" justify="end" align="middle">
                                            <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
                                        </Row>
                                    </Col>
                                </Row>

                                <div style={{ padding: '30px' }}>
                                    <Row gutter={16}>

                                        {this.state.totem ?
                                            <React.Fragment>
                                                <Row style={{ height: 100, marginBottom: 0 }}>
                                                    <h1 style={{ height: 60, color: 'black', textAlign: 'center' }}><img style={{ width: 50 }} alt="img" src={this.state.totem.foto.map((item) => {
                                                        return item.downloadUrl;
                                                    })} /> {this.state.totem.nombre}</h1>
                                                </Row>
                                            </React.Fragment>
                                            : null}

                                        {this.state.passwordSuccess ?
                                            data !== undefined ?
                                                data.map((d, index) => {
                                                    return (
                                                        <Col id={'tipo-atencion-' + d.id} key={d.id} xs={24} lg={8} style={{ marginBottom: 20 }}>
                                                            <Card style={{ border: '1px solid black' }} headStyle={{ fontSize: 28, textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 800 }} title={d.nombre} bordered={false}>
                                                                <ModuloTotem stitchService={this.props.stitchService} print={(numeroTomado) => this.print(numeroTomado)} idSucursal={this.props.match.params.idSucursal} tipoAtencion={d.id}></ModuloTotem>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })
                                                :
                                                <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                                                    <Meta
                                                        avatar={
                                                            <Avatar shape="square" size="large" src='' />
                                                        }
                                                    />
                                                </Skeleton>
                                            : null
                                        }
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </NoPrint>
                </PrintProvider>
            );
        }
    }
}

export default observer(Totem);
