import React, { Component } from 'react';
import logo from '../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../history';
import { observable } from "mobx";
import { observer } from 'mobx-react';

import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  notification,
  badge,
  Rate,
  message
} from 'antd';

import es_ES from 'antd/lib/locale-provider/es_ES';
import authAxios from '../utils/authAxios';

const { Option } = AutoComplete;
const { Meta } = Card;

class Modulos extends Component {

  constructor(props) {
    super(props);
    this.locationWhere = null;

    this.state = {
      loading: false,
      valueSearch: "",
      dataSearch: [],
      icon: null,
      iconSelected: null,
      tiempoEstimado: null,
      numeroAtendiendo: null,
      numeroDisponible: null,
      loadingTomandoNumero: false,
      moduloNombre: null,
      loading: false,
      id_proceso_caja: null,
      numeroActualTomado: null,
      atendiendo: false,
      llamando: false,
      calificar: false,
      raiting: null,
      tryError: 1,
    }
  }

  componentDidMount() {
    notification.destroy();

    const args = {
      message: 'Importante',
      description: 'Una vez tomes tu número debes acudir a la sucursal, si no estás a tiempo perderas tu puesto y otro tomará tu posición.',
      duration: 0,
    };

    notification.open(args);
    this.watchCollections();
    this.init();
  }

  watchCollections = async () => {
    const mongodb = await this.props.stitchService();
    this.tenants = mongodb.db("production").collection("tenants");
    this.settings = mongodb.db("production").collection("settings");
    this.clientes = mongodb.db("production").collection("clientes");
    this.modulos = mongodb.db("production").collection("modulos");
    this.filas = mongodb.db("production").collection("filas");

    const filas = await this.filas.watch();

    /*
    const tenants = await this.tenants.watch();
    const settings = await this.settings.watch();
    const clientes = await this.clientes.watch();
    const modulos = await this.modulos.watch();

    // Set up a change event handler function for the stream
    tenants.onNext((data) => {
      console.log('actualizando tenants')
    });

    settings.onNext((data) => {
      console.log('actualizando settings')
    });

    clientes.onNext((data) => {
      console.log('actualizando clientes')
    });

    modulos.onNext((data) => {
      console.log('actualizando modulos')
    });
    */

    filas.onNext(async (data) => {

      console.log(data.operationType);
      console.log(data.documentKey._id.toString());
      console.log(this.state.idNumeroFila);
      console.log(this.state.numeroEliminado);

      if (data.operationType === 'delete') {
        if (data.documentKey._id.toString() !== this.state.idNumeroFila) {
          if (this.state.numeroActualTomado === null) {
            this.setState({ loadingTomandoNumero: true });
            await this.updateScreen();
            this.setState({ loadingTomandoNumero: false });
          } else {
            await this.updateScreen();
          }
        }

        if (this.state.numeroEliminado !== data.documentKey._id.toString()) {
          if (data.documentKey._id.toString() === this.state.idNumeroFila) {

            this.setState({
              numeroEliminado: data.documentKey._id.toString()
            });

            await this.cancelarNumero();
            await this.init();
          }
        }
      }

      if (data.operationType === 'update') {
        if (data.fullDocument) {
          if (data.fullDocument._id.toString() !== this.state.idNumeroFila) {
            if (this.state.numeroActualTomado === null) {
              this.setState({ loadingTomandoNumero: true });
              await this.updateScreen();
              this.setState({ loadingTomandoNumero: false });
            } else {
              await this.updateScreen();
            }
          }

          if (data.fullDocument._id.toString() === this.state.idNumeroFila) {

            if (data.fullDocument.estado === 'Atendiendo') {
              var idSucursal = this.props.match.params.idSucursal;

              const fila = await authAxios.get('/tenant/' + idSucursal + '/fila/' + this.state.idNumeroFila, {
                params: {
                  id: this.state.idNumeroFila,
                }
              });

              this.setState({
                llamando: true,
                calificar: false,
                numeroAtendiendo: this.state.numeroDisponible,
                moduloNombre: fila.data.modulo ? fila.data.modulo.nombre : ''
              });
            }

            if (data.fullDocument.estado === 'Atendido') {
              this.setState({ calificar: true, llamando: false });
            }
          }
        }
      }
    });
  }

  updateScreen = async () => {
    var idSucursal = this.props.match.params.idSucursal;
    var tipoAtencion = this.props.match.params.tipoAtencion;

    return new Promise(async (resolve, reject) => {
      try {
        const screen = await authAxios.get('/tenant/' + idSucursal + '/fila', {
          params: {
            filter: {
              tipoAtencion
            },
          }
        });

        this.setState({
          numeroAtendiendo: screen.data.numeroAtendiendo ? screen.data.numeroAtendiendo.numero : 0,
          tiempoEstimado: screen.data.tiempoEstimado,
          numeroDisponible: this.state.numeroActualTomado === null ? screen.data.numero : this.state.numeroActualTomado,
        });

        resolve(true)
      } catch (e) {
        reject(e);
      }
    })

  }

  async tomarNumero() {
    var idSucursal = this.props.match.params.idSucursal;

    try {
      this.setState({ loadingTomandoNumero: true });

      await this.updateScreen();

      const numeroTomado = await authAxios.put('/tenant/' + idSucursal + '/fila/' + this.state.idNumeroFila, {
        id: this.state.idNumeroFila,
        data: {
          estado: 'Pendiente',
          numero: this.state.numeroDisponible,
          tiempoEstimado: this.state.tiempoEstimado,
          numeroAtendiendo: this.state.numeroAtendiendo.id
        },
      });

      this.setState({
        loadingTomandoNumero: false,
        atendiendo: true,
        numeroActualTomado: numeroTomado.data.numero,
      });
    } catch (e) {
      console.log(e);
      message.error(e.message);

      var idSucursal = this.props.match.params.idSucursal;
      var tipoAtencion = this.props.match.params.tipoAtencion;
      var nombreTipoAtencion = this.props.match.params.nombreTipoAtencion;

      history.push('/atencion/' + nombreTipoAtencion + '/' + tipoAtencion + '/' + idSucursal);

      this.setState({
        loadingTomandoNumero: false,
      });
    }

  }

  async cancelarNumero() {

    var idSucursal = this.props.match.params.idSucursal;
    this.setState({ loadingTomandoNumero: true });

    try {
      this.setState({
        atendiendo: false,
        llamando: false,
        numeroActualTomado: null,
        idNumeroFila: null,
        numeroEliminado: null,
      });

      await authAxios.delete('/tenant/' + idSucursal + '/fila/', {
        params: {
          ids: [this.state.idNumeroFila]
        }
      });

      await this.init();

    } catch (e) {
      console.log(e);
    }
  }

  init = async (isRealTime = false) => {

    var idSucursal = this.props.match.params.idSucursal;
    var tipoAtencion = this.props.match.params.tipoAtencion;

    this.setState({
      loadingTomandoNumero: true,
    });

    const sucursal = await authAxios.get('tenant/' + idSucursal, {
      params: {
        filter: {
        },
      }
    });

    if (sucursal.data.settings.activacion) {
      this.setState({ infoSucursal: sucursal.data });
    } else {
      alert('Lo sentimos la sucursal ha sido cerrada, serás redireccionado a la ventana de inicio');
      notification.destroy();

      const location = {
        pathname: '/',
      }

      history.push(location);
    }

    if (isRealTime === true) {
      return;
    }

    const tipoAtencion = await authAxios.get('/tenant/' + idSucursal + '/tipo-atencion/' + tipoAtencion, {
      params: {
        filter: {
        },
      }
    });

    this.setState({ tipoAtencion: tipoAtencion.data });

    const screen = await authAxios.get('/tenant/' + idSucursal + '/fila', {
      params: {
        filter: {
          tipoAtencion: this.props.match.params.tipoAtencion
        },
      }
    });

    this.setState({
      numeroAtendiendo: screen.data.numeroAtendiendo ? screen.data.numeroAtendiendo.numero : 0,
      tiempoEstimado: screen.data.tiempoEstimado,
      numeroDisponible: screen.data.numero,
      loadingTomandoNumero: false
    })

    const fila = await authAxios.post('/tenant/' + idSucursal + '/fila', {
      data: {
        tipoAtencion: tipoAtencion.data.id,
        estado: 'Visualizando'
      },
    });

    this.setState({
      idNumeroFila: fila.data.id,
    });
  };

  home = () => {
    history.goBack();
  }

  async calificar(val) {
    var idSucursal = this.props.match.params.idSucursal;
    this.setState({ raiting: val });

    try {
      await authAxios.put('/tenant/' + idSucursal + '/fila/' + this.state.idNumeroFila, {
        id: this.state.idNumeroFila,
        data: {
          calificacion: val
        }
      });

      const location = {
        pathname: '/',
      }

      history.push(location);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const Option = Select.Option;
    const { loading, data, dataSearch, infoSucursal } = this.state;

    return (
      <div className="App">
        <div id="BodyModulo" className="Rooms-Home">
          <Row className="App-Header-Rooms" style={{ marginBottom: 20 }}>
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
              <Row className="logoHeader" type="flex" justify="start" align="middle">
                <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>

          <Col className="sucursal-view" lg={{ span: 6 }} xs={{ span: 24 }} style={{ padding: 10 }} >

            {infoSucursal ?
              <Card
                hoverable
                style={{ width: '100%', marginTop: 0 }}
                cover={<img alt="img" src={infoSucursal.settings.backgroundImages.map((item) => {
                  return item.publicUrl;
                })} />}
              >
                <h4><img style={{ width: 200 }} alt="img" src={infoSucursal.cliente.foto.map((item) => {
                  return item.downloadUrl;
                })} /> {infoSucursal.id_cliente}</h4>
                <h4 style={{ fontWeight: '600' }}><Icon type="info-circle" /> {infoSucursal.name}</h4>
                <h4 style={{ fontWeight: '600' }}><Icon type="clock-circle" /> {moment(infoSucursal.settings.horarioInicio).format('HH:mm') + ' - ' + moment(infoSucursal.settings.horarioCierre).format('HH:mm')}</h4>
                <h4 style={{ fontWeight: '600' }}><Icon type="home" /> {infoSucursal.settings.ubicacionGoogle}</h4>
                <h4 style={{ fontWeight: '600' }}><Icon type="phone" /> {infoSucursal.settings.telefono}</h4>
              </Card>
              :
              <Skeleton paragraph={{ rows: 10 }} loading={loading} avatar active>
                <Meta
                  avatar={
                    <Avatar shape="square" size="large" src='' />
                  }
                />
              </Skeleton>
            }
          </Col>

          <Col className="modulo-view mod-view" lg={{ span: 18 }} xs={{ span: 24 }} style={{ padding: 10 }} >
            {infoSucursal ?
              <div className="panel-modulo" style={{ background: '#ECECEC', padding: '30px' }}>
                <Row className="remove-margin" gutter={16}>
                  <Col className={'info-cliente-modulo'} lg={{ span: 12 }} xs={{ span: 12 }}>
                    <Card hoverable={true} bordered={false}>
                      <Meta
                        avatar={
                          <img style={{ width: 50, height: 50 }} shape="square" size="large" src={infoSucursal.cliente.foto.map((item) => {
                            return item.downloadUrl;
                          })} />
                        }
                        title={infoSucursal.cliente.nombre}
                      />
                    </Card>
                  </Col>
                  <Col className={'info-sucursal-modulo'} lg={{ span: 12 }} xs={{ span: 12 }}>
                    <Card hoverable={true} bordered={false}>
                      <Meta
                        title={infoSucursal.name}
                        description={infoSucursal.settings.ubicacionGoogle}
                      />
                    </Card>
                  </Col>
                  <Col className={'div-t-border'} lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Card title="Tipo de Atención" bordered={false}>
                      <label>{this.state.tipoAtencion ? this.state.tipoAtencion.nombre : <Icon style={{ marginRight: 10 }} type="loading"></Icon>}</label>
                    </Card>
                  </Col>
                  <Col className={'div-m-border'} lg={{ span: 8 }} xs={{ span: 12 }}>
                    {this.state.numeroAtendiendo != null ?
                      <Card title="Número Actual" bordered={false}>
                        <label>{this.state.numeroAtendiendo}</label>
                      </Card>
                      :
                      <Card title="Número Actual" bordered={false}>
                        <Icon style={{ marginRight: 10 }} type="loading"></Icon> En espera...
                      </Card>
                    }
                  </Col>
                  <Col className={'div-m-border'} lg={{ span: 8 }} xs={{ span: 12 }}>
                    {this.state.numeroAtendiendo != null ?
                      <Card title="Tiempo Estimado" bordered={false}>
                        <label>{this.state.tiempoEstimado} minuto(s)</label>
                      </Card>
                      :
                      <Card title="Tiempo Estimado" bordered={false}>
                        <Icon style={{ marginRight: 10 }} type="loading"></Icon> En espera...
                      </Card>
                    }
                  </Col>
                </Row>
              </div>
              : null}
            {this.state.calificar == false ?
              this.state.llamando == false ?
                <Row type="flex" justify="start" align="middle">
                  {this.state.loadingTomandoNumero == false ?
                    !this.state.atendiendo ?
                      <a style={{ margin: 'auto', marginTop: 50 }} onClick={() => this.tomarNumero()}>
                        <Row className="ButtonLlamar" type="flex" justify="center" align="middle">
                          <Row>
                            {this.state.numeroDisponible != null ?
                              <>
                                <h5 style={{ fontWeight: 600, marginTop: 120, color: 'white', fontSize: 18 }}>
                                  Tomar Número {this.state.numeroDisponible}
                                </h5>
                              </>
                              :
                              <Icon style={{ color: 'white', marginTop: 150, fontSize: 30 }} type="loading" className="loadingNumero"></Icon>
                            }
                          </Row>
                        </Row>
                      </a>
                      :
                      <div style={{ margin: 'auto', marginTop: 50 }}>
                        <Row className="ButtonAtendiendo" type="flex" justify="center" align="middle">
                          <h5 style={{ marginTop: 120, color: 'white', fontSize: 18, fontWeight: 600 }}>
                            Eres el número {this.state.numeroDisponible}
                          </h5>
                        </Row>
                        <Row style={{ marginTop: 40 }} type="flex" justify="center" align="middle" className="dejarNumero">
                          <Button onClick={() => this.cancelarNumero()} size={'large'} style={{ borderRadius: 20, fontWeight: 600, width: '100%', backgroundColor: 'black', borderColor: '#FC450A', color: 'white' }} type="primary">
                            Dejar Número
                          </Button>
                        </Row>
                      </div>
                    :
                    <a style={{ margin: 'auto', marginTop: 50 }}>
                      <Row type="flex" justify="start" align="middle">

                        <h5 style={{ fontSize: 18, color: window.innerWidth <= 768 ? 'white' : 'black', marginTop: 100 }}>
                          <Icon style={{ marginRight: 10 }} type="loading"></Icon> Cargando...
                        </h5>
                      </Row>
                    </a>
                  }
                </Row>
                :

                <Row type="flex" justify="start" align="middle">
                  <div style={{ margin: 'auto', marginTop: 0 }}>
                    <Row type="flex" justify="center" align="middle">
                      <label className="text-modulo" style={{ color: 'black', marginTop: 20 }}>Estás siendo atendido ahora en {this.state.moduloNombre}</label>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                      <label className="text-modulo" style={{ color: 'black', marginTop: 20 }}>Muestra esta pantalla a la persona que te atienda para verificar tú turno.</label>
                    </Row>

                    <Row style={{ marginTop: 50 }} className="ButtonAtendiendo" type="flex" justify="center" align="middle">
                      <h5 style={{ marginTop: 120, color: 'white', fontSize: 52 }}>
                        {this.state.numeroActualTomado}
                      </h5>
                    </Row>
                  </div>
                </Row>

              :

              <Row>
                <Row type="flex" justify="start" align="middle">
                  <a style={{ margin: 'auto', marginTop: 0 }}>
                    <Row type="flex" justify="center" align="middle">
                      <label className="text-modulo" style={{ color: 'black', marginTop: 20 }}>
                        <Icon style={{ color: '#E35018' }} type="smile" theme="outlined" /> Gracias por usar Nomasfilas.
                      </label>
                    </Row>

                    <Row type="flex" justify="center" align="middle" style={{ marginTop: 20 }}>
                      <label className="text-modulo" style={{ marginTop: 0 }}>Califica tú atención!</label>
                    </Row>

                    <Row type="flex" justify="center" align="middle" style={{ marginTop: 20 }}>
                      <Rate onChange={(val) => this.calificar(val)} style={{ fontSize: '3em' }} disabled={false} value={this.state.raiting} />
                    </Row>
                  </a>
                </Row>
              </Row>
            }
          </Col>
        </div>
      </div>
    );
  }
}

export default observer(Modulos);
